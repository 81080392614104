import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { globalAppConfig } from "../../config";
import PlotAPI from "../../plotAPI";
import plotLogo from "./../../assets/png/logo.png";

import Theme from "../../../packs/globalTheme";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Helper from "../../Helper";
import PromptOnLink from "../MagicLinks/promptOnLink";
import DarkTheme from "../UI/Themes/DarkTheme";
import LoginCode from "./loginCode";
import LoginForm from "./loginForm";

const Login: React.FC<any> = () => {
    const context = React.useContext(GlobalStoreContext);
    const [showLogin, setShowLogin] = useState(true);
    const [email, setEmail] = useState<string | null>(null);
    const [mobile, setMobile] = useState<string | null>(null);
    const [passcode, setPasscode] = useState("");
    const [useEmail, setUseEmail] = useState(false);
    const [useMobile, setUseMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timestamp, setTimestamp] = useState<string | null>(null);
    const [searchParams, setSearchParams] = useState({
        return_url: null,
        team_id: null,
        project_token: null,
    });
    // const [teamRequiresSSO, setTeamRequiresSSO] = useState(null);

    useEffect(() => {
        checkURLParams();
    }, []);

    const parseUrlSearchParams = (params) => {
        const updatedSearchParams = { ...searchParams };
        for (const [key, value] of params) {
            updatedSearchParams[key] = value;
            if (key === "return_url" && !updatedSearchParams.project_token) {
                const return_url = new URL(value);
                parseUrlSearchParams(return_url.searchParams);
            }
        }
        setSearchParams(updatedSearchParams);
    };

    const checkURLParams = () => {
        const params = new URLSearchParams(document.location.search);
        parseUrlSearchParams(params);
    };

    const postLoginRedirect = async () => {
        let redirect_url;
        if (searchParams.project_token) {
            redirect_url = `${Helper.baseUrlAltSubdomain("projects")}/projects/${searchParams.project_token}`;
        } else {
            redirect_url = "auth/redirect/";
        }
        window.location.href = redirect_url;
    };

    const onFinishPasscode = () => {
        setLoading(true);
        const values = {
            user_email: email !== "" || null ? email : null,
            phone_number: mobile !== "" ? mobile : null,
            passcode: passcode,
        };

        PlotAPI.userOTPPasscode(values)
            .then((response) => {
                if (response.error) {
                    notification.error({
                        message: response.error_description,
                        description: "Please check your entries and try again",
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                    setLoading(false);
                    return;
                }
                if (response) {
                    postLoginRedirect();
                } else {
                    notification.error({
                        message: "Unable to Verify Passcode",
                        description: "Please check your passcode and try again",
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                }

                setLoading(false);
            })
            .catch(() => {
                notification.error({
                    message: "Unable to Verify Passcode",
                    description: "Please check your passcode and try again",
                    duration: globalAppConfig.NOTIFICATION_DURATION,
                });

                setLoading(false);
            });
    };

    const resetLogin = () => {
        setShowLogin(true);
        setUseEmail(false);
        setUseMobile(false);
        setLoading(false);
        setEmail(null);
        setMobile(null);
        document.getElementById("email-address").value = "";
        document.getElementById("mobile-number").value = "";
    };

    return (
        <>
            <Theme themeOverride={DarkTheme}>
                <div className="primaryContainer">
                    <PromptOnLink />
                    <div className="item items-center">
                        <div className="flex flex-col gap-2 items-center">
                            <div className={Helper.classNames(context.isMobile ? "mx-12!" : "w-1/2")}>
                                <img className="w-1/2 mb-4!" src={plotLogo} />
                                {showLogin ? (
                                    <>
                                        <LoginForm
                                            email={email}
                                            mobile={mobile}
                                            useEmail={useEmail}
                                            useMobile={useMobile}
                                            setUseEmail={setUseEmail}
                                            setUseMobile={setUseMobile}
                                            setEmail={setEmail}
                                            setMobile={setMobile}
                                            setTimestamp={setTimestamp}
                                            loading={loading}
                                            setLoading={setLoading}
                                            setShowLogin={setShowLogin}
                                            resetLogin={resetLogin}
                                        />
                                    </>
                                ) : (
                                    <LoginCode
                                        email={email}
                                        mobile={mobile}
                                        passcode={passcode}
                                        setPasscode={setPasscode}
                                        timestamp={timestamp}
                                        resetLogin={resetLogin}
                                        onFinishPasscode={onFinishPasscode}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {!context.isMobile && (
                        <div className="sidebar">
                            <div className="content">
                                <h1>Jobsite Management Made Easy</h1>
                                <p>
                                    It's {new Date().getFullYear()}, stop relying on phone calls, text messages and
                                    emails to coordinate your site. You are just a few short steps away from seeing what
                                    your Jobsite Communications could be like.
                                </p>
                                <p>
                                    It only takes one person about 10 minutes to set up a project on PLOT. Share it with
                                    your project team and get their thoughts.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </Theme>
        </>
    );
};

export default Login;
